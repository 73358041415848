import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../../../Services/common-services.service';

@Injectable({ providedIn: 'root' })
export class DataService {
  constructor(private api: ApiService) {}

  async fetchZoneData(): Promise<any> {
    try {
      const data: any[] = await firstValueFrom(this.api.get('api/zone'));
      const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
      return sortedData;
    } catch (error) {
      throw error;
    }
  }

  async createZoneData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.post('api/zone', payload));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async amendZoneData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.put('api/zone', payload));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteZoneData(id: number): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.delete(`api/zone/${id}`));
      return data;
    } catch (error) {
      throw error;
    }
  }
}
